import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import type { AxiosResponse } from 'axios';
import type { BankAccountFormT } from '@src/models/settings/financial/BankAccountForm.schema';
import type { DebitCardFormSubmitData } from '@src/components/DebitCardForm/interface';

const usePaymentInfo = (): {
  addCard: UseMutateAsyncFunction<AxiosResponse<any>, any, any, unknown>;
  addDirectDeposit: UseMutateAsyncFunction<AxiosResponse<any>, any, any, unknown>;
  isAddDirectDepositLoading: boolean;
  isAddCardLoading: boolean;
} => {
  const { axios } = useNetworkRequest();

  const { mutateAsync: addDirectDeposit, isLoading: isAddDirectDepositLoading } = useMutation(
    (values: BankAccountFormT) =>
      axios.post('/v3/professional/external_accounts', {
        external_account: {
          routing_number: values.routing_number,
          account_number: values.account_number,
          type: 'bank_account',
        },
      }),
  );

  const { mutateAsync: addCard, isLoading: isAddCardLoading } = useMutation(
    (values: DebitCardFormSubmitData & { stripe_token?: string }) =>
      axios.post('/v3/professional/external_accounts', {
        external_account: {
          stripeToken: values.stripe_token,
        },
      }),
  );

  return {
    addCard,
    addDirectDeposit,
    isAddDirectDepositLoading,
    isAddCardLoading,
  };
};

export default usePaymentInfo;
