import { Box, Button, HStack, StripeFormFields } from '@medely/ui-kit';
import { useStripeCard } from '@medely/web-components/hooks';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FixedFooterLayout } from '../FixedFooterLayout';
import { useProNavigate } from '../../contexts';
import usePaymentInfo from '@src/hooks/usePaymentInfo';
import type { DebitCardFormProps, DebitCardFormSubmitData } from './interface';

function ButtonActions({ onError, onSuccess }: DebitCardFormProps) {
  const { navigate } = useProNavigate();
  const { handleSubmit, setError } = useFormContext();
  const { authorize } = useStripeCard();
  const { addCard, isAddDirectDepositLoading, isAddCardLoading } = usePaymentInfo();

  function validateErrors(data: DebitCardFormSubmitData) {
    Object.keys(data).forEach((key): void => {
      if (!data[key as keyof DebitCardFormSubmitData]) {
        const requiredError = {
          message: 'The field is required',
          type: 'required',
        };
        setError(key, requiredError);

        return;
      }

      const error = data[key as keyof DebitCardFormSubmitData].error;
      if (error) {
        setError(key, error);
      }
    });
  }

  async function onSubmit(data: DebitCardFormSubmitData & { stripe_token?: string }) {
    validateErrors(data);

    const token = await authorize();
    if (token?.id) {
      data.stripe_token = token.id;
      addCard(data, {
        onError,
        onSuccess,
      });
    }
  }

  function onCancel() {
    navigate('PayoutTaxes');
  }

  return (
    <HStack gap={1}>
      <Box width={'50%'}>
        <Button variant="outlined" fullWidth onClick={onCancel}>
          Cancel
        </Button>
      </Box>
      <Box width={'50%'}>
        <Button
          fullWidth
          onClick={() => handleSubmit((values) => onSubmit(values as DebitCardFormSubmitData))()}
          loading={isAddDirectDepositLoading || isAddCardLoading}
        >
          Save
        </Button>
      </Box>
    </HStack>
  );
}

export const DebitCardForm = ({ onError, onSuccess }: DebitCardFormProps) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <FixedFooterLayout footerContent={<ButtonActions onError={onError} onSuccess={onSuccess} />}>
        <StripeFormFields />
      </FixedFooterLayout>
    </FormProvider>
  );
};
